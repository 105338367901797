<template>
  <div>
    <p class="text-2xl mb-6">Cuentas por pagar detallado</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <select-grupo-negocio
              :SelectDefault="-1"
              :todos="true"
              @getSelect="getGrupoNegocio"
              :dense="true"
              :outlined="true"
            ></select-grupo-negocio>
          </v-col>
          <v-col cols="3">
            <dos-fechas
              :fechas="[
                FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
              ]"
              @getFechas="getFechas"
              :icon="true"
            ></dos-fechas>
          </v-col>
          <v-col cols="3">
            <BuscarProveedor
              ref="BuscarProveedorRef"
              :add="false"
              :icon="true"
              :label="`Proveedor`"
              @GetProveedor="GetProveedor"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="filtros.id_estado_pago"
              :items="estados"
              item-text="nombre"
              item-value="id"
              filled
              label="Estado pago"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="getVer"
          :acciones="['VER']"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
    </v-card>
    <ModalViewCompra ref="ModalViewCompraRef"></ModalViewCompra>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue'
import { ref } from '@vue/composition-api'
import CompraServices from '@/api/servicios/inventario/CompraServices'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import store from '@/store'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import BuscarCliente from '@/views/sistema/administracion/archivo/cliente/components/BuscarCliente.vue'
import ModalVerComanda from '@/views/sistema/administracion/dashboard/componentes/ModalVerComanda.vue'
import BuscarProveedor from '../../proveedor/componentes/BuscarProveedor.vue'
import ModalViewCompra from '../../factura/componentes/ModalViewCompra.vue'
// demos

export default {
  components: { TablaReporte, SelectGrupoNegocio, DosFechas, BuscarProveedor, ModalViewCompra },
  setup() {
    const filtros = ref({
      id_proveedor: -1,
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
      id_grupo_negocio: -1,
      id_estado_pago: -1,
    })
    const estados = ref([
      { id: -1, nombre: 'TODOS' },
      { id: 63, nombre: 'PENDIENTES' },
      { id: 64, nombre: 'ABONADOS' },
      { id: 65, nombre: 'PAGADOS' },
    ])
    const nombreReporte = ref(`Cuentas por cobrar detallado`)
    const ModalViewCompraRef = ref()

    const cabecera = ref([
      { text: 'ID', value: 'id' },
      { text: 'Nro Documento', value: 'numero_documento' },
      { text: 'Fecha Factura', value: 'fecha_factura' },
      { text: 'Documento', value: 'proveedor_documento' },
      { text: 'proveedor', value: 'proveedor_nombre' },
      { text: 'SubTotal', value: 'subtotal' },
      { text: 'impuesto', value: 'impuesto' },
      { text: 'IVA', value: 'iva' },
      { text: 'Total', value: 'total' },
      { text: 'Abono', value: 'total_pago' },
      { text: 'Resta', value: 'resta' },
      {
        text: 'Estado',
        value: 'descripcion_estado_pago',
        format: {
          chip: true,
          size: 'x-small',
          color: ['error', 'warning', 'success'],
          valores: ['POR PAGAR', 'ABONADA', 'PAGADA'],
        },
      },
    ])
    const datos = ref([])
    const refModalComanda = ref()
    const cargando = ref(false)
    const comandaSelect = ref({})
    const openModalComanda = ref(false)
    const AbrirModalComanda = datos => {
      ModalViewCompraRef.value.abrir(datos.id)
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }

    const validar = () => {
      let validado = true

      return validado
    }

    const buscarDatos = () => {
      cargando.value = true
      CompraServices.ReporteCXPProveedorDetallado(filtros.value)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            datos.value = response.data.datos
            nombreReporte.value = `Cuentas por cobrar detallado`

            if (datos.value.length == 0) {
              store.commit('setAlert', {
                message: 'No hubo resultado a la busqueda',
                type: 'info',
              })
            }
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
              error: response,
              funcion: 'buscarDatos',
            })
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: response.data.mensaje,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'buscarDatos',
          })
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const getVer = dato => {
      console.log(dato)
      AbrirModalComanda(dato)
    }

    const getGrupoNegocio = dato => {
      filtros.value.id_grupo_negocio = dato.id
    }
    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha.desde = datos.desde
      filtros.value.fecha.hasta = datos.hasta
    }
    const GetProveedor = dato => {
      alert('as')
      if (dato != undefined) {
        filtros.value.id_proveedor = dato.id
      } else {
        filtros.value.id_proveedor = -1
        store.commit('setAlert', {
          message: 'vacio',
          type: 'warning',
        })
      }
    }

    return {
      cabecera,
      datos,
      validar,
      buscarDatos,
      cargando,
      nombreReporte,
      getGrupoNegocio,
      getVer,
      refModalComanda,
      filtros,
      FuncionesGenerales,
      GetProveedor,
      getFechas,
      estados,
      AbrirModalComanda,
      openModalComanda,
      GetOpenModalComanda,
      comandaSelect,
      ModalViewCompraRef,
    }
  },
}
</script>
